import { PromotionUtils } from '../utils'
import PROMOTION_CONFIG from '../config'

import { template, htmlDecode as _htmlDecode } from '@shein/common-function'

const htmlDecode = (msg) => {
  return typeof window != 'undefined' ? _htmlDecode({ text: msg }) : msg
}
const { percentNumWithoutSign, discountNumCn, numberToPercentage, decimalToPercent } = PromotionUtils
const { TitlePromotionTypeIds, BrandProductPromotionTypeIds } = PROMOTION_CONFIG

class TransformPromotionTip {
  /**
   *
   * wiki:wiki.pageId=729547087
   * api:https://soapi.sheincorp.cn/application/423/routes/74156
   * @param {Object} language
   * @param {Object} promotionInfo 促销活动信息
   *   promotionInfo.promotion_range_info {Object} 活动门槛及差额信息
   *   promotionInfo.rules {Array} 活动门槛
   *   promotionInfo.rules.type 门槛类型 1、满X件 2、每满X件 3、满X元 4、每满X元 5、无门槛
   *   promotionInfo.rules.value 门槛数值 当门槛为1、2、3、4时传具体的数值，如果门槛为5则传0
   *   promotionInfo.rules.discount 门槛的优惠规则
   *   promotionInfo.rules.discount.type 优惠类型 1、全部按原价打折 2、其中X件按原价打折 3、全部按特价打折 4、其中X件按特价打折 5、按固定金额售卖 6、减元 7、M元任选X件
   *   8、 送优惠券 9、按固定金额换购 10、按特价打折换购 11、送赠品 12、第2、3件减元 13、第2、3件按原价打折 14、第2、3件按特价打折 15、不打折
   *   全部按原价打折：买N折N,会员专享折扣,APP专享折扣,常规闪购,限时折扣,直播闪购,全场满金额打折,满金额打折,打折限购
   *   部分商品按原价打折：买折
   *   全部按特价打折：会员专享折扣,满金额打折,打折限购,买N折N,全场满金额打折,APP专享折扣,限时折扣
   *   部分商品按特价打折：买折
   *   按固定金额售卖：常规闪购、直播闪购
   *   减元：满减、全场满减
   *   打包金额售卖：X元N件
   *   送优惠券：全场订单返券、订单返券
   *   按固定金额换购：加价购、全场加价购
   *   按特价打折换购：加价购、全场加价购
   *   送赠品：满赠、买赠
   *    不打折：	通用选品、打折限购
   *   promotionInfo.rules.discount.value 优惠金额 当优惠形式为1、2、3、4、5、6、9、10、12、13、14时，传其配置的折扣数值或者金额；如果为非以上优惠形式时，则传0
   * @param {String} scene 页面场景
   */
  constructor({ language = {}, promotionInfo = {}, scene = 'cart', lang = '' }) {
    const { goods, promotion_id, type_id } = promotionInfo
    const { type, brand_name } = goods
    this.promotionInfo = promotionInfo
    this.language = language
    this.scene = scene
    this.content = {}
    this.content.id = promotion_id // 活动id
    this.content.typeId = type_id // 活动类型id
    this.content.tips = { text: '', list: [] } // 列表页tips
    this.content.tip = '' // 购物车展示的提示语
    this.content.difference = { text: '', diff: '', diffAmount: null, diffType: '' } // 差额
    this.content.action = 0 // 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
    this.content.tipsIndex = 0 // 当前使用的提示语index (由于购物车可能有差额提示，导致展示更高一档提示语，此index为当前达到的档次)
    this.content.lable = '' // 活动标签
    this.content.style = {} // 促销样式
    this.content.title = '' // 促销组头标题
    this.content.discountListSubTip = '' // 优惠清单里的副提示语（pwa是问号打开后弹窗的内容）
    for(let key in TitlePromotionTypeIds){
      if(TitlePromotionTypeIds[key].indexOf(+type_id) > -1){
        this.content.title = `${(type == 4 && BrandProductPromotionTypeIds.includes(+type_id)) ? brand_name : '' } ${language[key]}`
      }
    }
    this.lang = lang
    this.content.diffAmount = null // 全场活动（满减、满折、返券）差额（按金额）
  }

  /**
   * 1.买折
   * 差额提示: -
   * 多档次: -
   * @discount.type 4: 部分商品按特价折扣; 2: 部分商品按原价折(默认)
   */
  handle1() {
    let { rules = [], promotion_range_info } = this.promotionInfo
    let { discount = {}, value = '' } = rules[0] || {}
    this.content.tips.list[0] = template(`<em>${value}</em>`, `<em>${percentNumWithoutSign(discount.value) }%</em>`, this.language.SHEIN_KEY_PWA_28905)
    // 购物车场景
    if ( this.scene == 'cart' && promotion_range_info ) {

      const { range, diff = {}, hit_range = {} } = promotion_range_info
      if(range <= 0) { // 未满足门槛
        if(discount.type == '4') { // 按特价
          this.content.tips.text = template(
            `<em>${ value }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            `<em>${ diff?.value }</em>`,
            this.language.SHEIN_KEY_PWA_28578
          )
        } else {
          this.content.tips.text = template(
            `<em>${ value }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            `<em>${ diff?.value }</em>`,
            this.language.SHEIN_KEY_PWA_28575
          )
        }
      } else if(diff?.value == value) {
        if(discount.type == '4') {
          this.content.tips.text = template(
            `<em>${ hit_range?.hit_range_num }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            this.language.SHEIN_KEY_PWA_28591
          )
        } else {
          this.content.tips.text = template(
            `<em>${ hit_range?.hit_range_num }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            this.language.SHEIN_KEY_PWA_28589
          )
        }
      } else if (diff?.value < value) {
        if(discount.type == '4') {
          this.content.tips.text = template(
            `<em>${ hit_range?.hit_range_num }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            `<em>${ diff?.value }</em>`,
            this.language.SHEIN_KEY_PWA_28592
          )
        } else {
          this.content.tips.text = template(
            `<em>${ hit_range?.hit_range_num }</em>`,
            `<em>${ decimalToPercent(discount.value) }</em>`,
            `<em>${ diff?.value }</em>`,
            this.language.SHEIN_KEY_PWA_28590
          )
        }
      }
      this.content.tip = this.content.tips.text //  + this.content.difference.text
      this.content.action = 1
      this.content.tipsIndex = 0
    } else {
      this.content.tips.text = this.content.tips.list[0] || ''
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 2.买赠
   * 差额提示: -
   * 多档次: -
   */
  handle2(){
    // 购物车场景
    let { rules = [], promotion_range_info = null, goods }  = this.promotionInfo
    let { value = '' } = rules[0] || {}
    if ( this.scene == 'cart' ) {
      if(promotion_range_info){
        const all_diff = promotion_range_info?.all_diff
        const range = promotion_range_info?.range
        const { type, value, value_amount } = all_diff && all_diff[0] || {}
        const difference = type == 1 ? value : (value_amount?.amountWithSymbol || '')
        const { type: brandType, brand_name  } = goods
        if(difference <= 0) {
          this.content.tips.list[0] = {
            '0': this.language.SHEIN_KEY_PWA_27776, // 未勾选附属品
            '1': this.language.SHEIN_KEY_PWA_27779, // 勾选附属品
          }
        } else {
          if (brandType == 4) {
            this.content.tips.list[0] = template(`<em>${difference}</em>`, brand_name, this.language.SHEIN_KEY_PWA_27780)
          } else {
            this.content.tips.list[0] = template(`<em>${difference}</em>`, this.language.SHEIN_KEY_PWA_27774)
          }
        }

        if ( (type == 1 && value == 0) || (type != 1 && value_amount?.usdAmount == 0) || (!all_diff) ) {
          this.content.action = 2
        } else {
          this.content.action = 1
        }
        this.content.tips.text = this.content.tips.list[0] || ''
        this.content.tip = this.content.tips.text
        this.content.tipsIndex = 0

        let index = Math.min(range - 1, rules.length - 1)
        // 外漏的赠品列表,取最高档次的有可售赠品列表
        let showFullGiftList = []
        while (index >= 0) {
          if(rules[index].list?.length){
            showFullGiftList = rules[index].list?.filter(goods => goods.on_sale_status == '1' && goods.stock_status == '1') || []
          }
          if(showFullGiftList.length){
            break
          }
          index--
        }
        this.content.showFullGiftList = showFullGiftList
      }
    } else {
      this.content.tips.list[0] = template(value, this.language.SHEIN_KEY_PWA_16152)
      this.content.tips.text = this.content.tips.list[0] || ''
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15881
  }

  /**
   * 3.限时活动/折上折
   * 单品活动，购物车场景不需处理
   */
  handle3(){
    this.content.tips.text = ''
  }

  /**
   * 4.满赠（全场）
   */
  handle4(){
    let { rules = [], promotion_logo_type, promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      if (this.scene === 'cart') {
        // let lowerProductRange = -1 // 有赠品列表的最低档 无赠品的档次不展示
        // rules.forEach((rule, index)=>{
        //   if(rule.list?.length && lowerProductRange == -1){
        //     lowerProductRange = index + 1
        //   }
        // })
        if(promotion_range_info){
          const { range, all_diff, next } = promotion_range_info
          let diff = all_diff && all_diff[range] || {}
          if (promotion_logo_type === 8) {
            this.content.action = 2

            const sheinClubLogo = '<img width="14" style="vertical-align:text-top" src="https://img.ltwebstatic.com/images3_ccc/2024/05/17/a0/1715937574afe2c4cdac01a40f11e5d66949c62994.png">'

            this.content.tip = {
              '0': this.language.SHEIN_KEY_PWA_31153.replace('{0}', sheinClubLogo), // 未选附属品
              '1': this.language.SHEIN_KEY_PWA_31156.replace('{0}', sheinClubLogo), // 已选附属品
            }
          } else {
            if (range <= 0) {
              this.content.action = 1
              this.content.tip = template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_27774)
            } else if(next !== 0) {
              // next 下一档位，为0时表示当前为最高档
              this.content.action = 2
              this.content.tip = {
                '0': template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_27775), // 未勾选附属品
                '1': template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_27778), // 勾选附属品
              }
            } else {
              this.content.action = 2
              this.content.tip = {
                '0': rules.length == 1 ? this.language.SHEIN_KEY_PWA_27776 : this.language.SHEIN_KEY_PWA_27777, // 未勾选附属品
                '1': this.language.SHEIN_KEY_PWA_27779, // 勾选附属品
              }
            }
          }
          this.content.diffAmount = diff?.value_amount || {}

          let index = Math.min(range - 1, rules.length - 1)
          // 外漏的赠品列表,取最高档次的有可售赠品列表
          let showFullGiftList = []
          while (index >= 0) {
            if(rules[index].list?.length){
              showFullGiftList = rules[index].list?.filter(goods => goods.on_sale_status == '1' && goods.stock_status == '1') || []
            }
            if(showFullGiftList.length){
              break
            }
            index--
          }
          this.content.showFullGiftList = showFullGiftList
        }
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15881
  }

  /**
   * 8.app专享
   */
  handle8(){
    this.content.lable = this.language.SHEIN_KEY_PWA_18045
  }

  /**
   * 9.买N折N
   * 差额提示: -
   * 多档次: 是
   * @discount.type 3: 全部按特价折扣; 1: 全部按原价折(默认)
   */
  handle9(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      rules.forEach((r, i) => {
        if (r.discount?.type == '3') {
          this.content.tips.list[i] = template(`<em>${r.value}</em>`, `<em>${((1 - r.discount.value) * 100).toFixed(0)}%</em>`, this.language.SHEIN_KEY_PWA_28907)
        } else {
          // if (['hk', 'tw'].includes(this.lang)) {
          //   this.content.tips.list[i] = template(`<em>${r.value}</em>`, `<em>${Number((r.discount.value * 10).toFixed(1)) }%</em>`, this.language.SHEIN_KEY_PWA_28906)
          // } else {
          this.content.tips.list[i] = template(`<em>${r.value}</em>`, `<em>${((1 - r.discount.value) * 100).toFixed(0)}%</em>`, this.language.SHEIN_KEY_PWA_28906)
          // }
        }
      })
      // 购物车场景
      if(this.scene == 'cart'){
        if (promotion_range_info) {
          const { range, next, diff = {} } = promotion_range_info
          if (range <= 0) {
            // 不满足最低档
            let rule = rules[0]
            if(rule.discount?.type == '3'){
              this.content.tips.text = template(
                `<em>${ rule.value }</em>`,
                `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
                `<em>${ diff?.value }</em>`,
                this.language.SHEIN_KEY_PWA_27742.replace('%', '')
              )
            } else {
              // if(['hk', 'tw'].includes(this.lang)){
              //   this.content.tips.text = template(
              //     `<em>${ rule.value }</em>`,
              //     `<em>${ Number((rule.discount.value * 10).toFixed(1)) }%</em>`,
              //     `<em>${ diff?.value }</em>`,
              //     this.language.SHEIN_KEY_PWA_27740.replace('%', '')
              //   )
              // } else {
              this.content.tips.text = template(
                `<em>${ rule.value }</em>`,
                `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
                `<em>${ diff?.value }</em>`,
                this.language.SHEIN_KEY_PWA_27740.replace('%', '')
              )
              // }
            }
            this.content.action = 1
            this.content.tipsIndex = 0
          } else if (next != '0') {
            // 满足最低档，但不满足最高档
            let rule = rules[range - 1]
            let nextRule = rules[range]
            if(rule.discount?.type == '3'){
              this.content.tips.text = template(
                `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
                `<em>${ diff?.value }</em>`,
                `<em>${ ((1 - nextRule.discount.value) * 100).toFixed(0) }%</em>`,
                this.language.SHEIN_KEY_PWA_27745.replace('%', '')
              )
            } else {
              // if(['hk', 'tw'].includes(this.lang)){
              //   this.content.tips.text = template(
              //     `<em>${ Number((rule.discount.value * 10).toFixed(1)) }%</em>`,
              //     `<em>${ diff?.value }</em>`,
              //     `<em>${ Number((nextRule.discount.value * 10).toFixed(1)) }%</em>`,
              //     this.language.SHEIN_KEY_PWA_27744.replace('%', '')
              //   )
              // } else {
              this.content.tips.text = template(
                `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
                `<em>${ diff?.value }</em>`,
                `<em>${ ((1 - nextRule.discount.value) * 100).toFixed(0) }%</em>`,
                this.language.SHEIN_KEY_PWA_27744.replace('%', '')
              )
              // }
            }
            this.content.action = 1
            this.content.tipsIndex = range - 1
          } else if (next == '0') {
            // 满足最高档
            let rule = rules[range - 1]
            if(rule.discount?.type == '3'){
              this.content.tips.text = template(
                `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
                this.language.SHEIN_KEY_PWA_27743.replace('%', '')
              )
            } else {
              // if(['hk', 'tw'].includes(this.lang)){
              //   this.content.tips.text = template(
              //   `<em>${ Number((rule.discount.value * 10).toFixed(1)) }%</em>`,
              //   this.language.SHEIN_KEY_PWA_27741.replace('%', '')
              //   )
              // } else {
              this.content.tips.text = template(
              `<em>${ ((1 - rule.discount.value) * 100).toFixed(0) }%</em>`,
              this.language.SHEIN_KEY_PWA_27741.replace('%', '')
              )
              // }
            }
            this.content.action = 0
            this.content.tipsIndex = range - 1
          }

          this.content.tip = this.content.tips.text + this.content.difference.text
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 10.常规闪购
   * 差额提示: -
   * 多档次: -
   * @single 单品限购数
   * @order 按订单限购数
   * @member 按用户限购数，仅在 memberNum > 0 时生效
   * 场景：
   * 1、单品限购+用户限购single\member有值 2、用户限购 仅member有值
   * 3、订单限购+用户限购order\member有值取小的那个值 4、单品限购+订单限购+用户限购single\order\member都有值，取single+order\member值小的那个

   */
  handle10(){
    if (this.scene == 'cart') {
      let { cart_limit = {}, flash_type = 1 }  = this.promotionInfo
      let { single, order, member } = cart_limit || {}
      let limit_order = member && order ? Math.min(+order, +member) : (member || order)
      let typeTxt = this.language.SHEIN_KEY_PWA_21419
      if(flash_type == 2) typeTxt = this.language.SHEIN_KEY_PWA_22001
      else if(flash_type == 3) typeTxt = this.language.SHEIN_KEY_PWA_22002
      else if(flash_type == 4 || flash_type == 5) typeTxt = this.promotionInfo.tagText || this.language.SHEIN_KEY_PWA_26340
      else if(flash_type == 6) typeTxt = this.language.SHEIN_KEY_PWA_29800
      let singleTxt = template(`<span>${single}</span>`, this.language.SHEIN_KEY_PWA_22003)
      let orderTxt = template(`<span>${limit_order}</span>`, this.language.SHEIN_KEY_PWA_22004)
      this.content.tips.list[0] = ''
      this.content.tips.list[1] = `<p style="text-transform: uppercase">${typeTxt}</p>${singleTxt}`
      this.content.tips.list[2] = `<p style="text-transform: uppercase">${typeTxt}</p>${orderTxt}`
      this.content.tips.list[3] = `<p style="text-transform: uppercase">${typeTxt}</p>${orderTxt}${singleTxt}`
      this.content.tips.text = this.content.tips.list[0] || ''
      this.content.tip = this.content.tips.text
      this.content.tipsIndex = 0
    }
    this.content.lable = this.scene === 'detail' ? this.language.SHEIN_KEY_PWA_15055 : ''
  }
  /**
   * 11.直播闪购
   * 差额提示: -
   * 多档次: -
   * @single 单品限购数
   * @order 按订单限购数
   * @member 按用户限购数，仅在 memberNum > 0 时生效
   * 场景：
   * 1、单品限购+用户限购single\member有值 2、用户限购 仅member有值
   * 3、订单限购+用户限购order\member有值取小的那个值 4、单品限购+订单限购+用户限购single\order\member都有值，取single+order\member值小的那个

   */
  handle11(){
    if (this.scene == 'cart') {
      let { cart_limit = {} }  = this.promotionInfo
      let { single, order, member } = cart_limit || {}
      let limit_order = member && order ? Math.min(+order, +member) : (member || order)
      let singleTxt = template(`<span>${single}</span>`, this.language.SHEIN_KEY_PWA_22003)
      let orderTxt = template(`<span>${limit_order}</span>`, this.language.SHEIN_KEY_PWA_22004)
      let typeTxt = this.language.SHEIN_KEY_PWA_25020
      this.content.tips.list[0] = ''
      this.content.tips.list[1] = `<p style="text-transform: uppercase">${typeTxt}</p>${singleTxt}`
      this.content.tips.list[2] = `<p style="text-transform: uppercase">${typeTxt}</p>${orderTxt}`
      this.content.tips.list[3] = `<p style="text-transform: uppercase">${typeTxt}</p>${orderTxt}${singleTxt}`
      /* 
      if(member){ //按用户限购数
        if (single && order) {
          this.content.tips.list[0] = template(`<em>${single}</em>`, `<em>${Math.min(+order, +member)}</em>`, this.language.SHEIN_KEY_PWA_17315)
        } else if (single) {
          this.content.tips.list[0] = template(`<em>${single}</em>`, `<em>${member}</em>`, this.language.SHEIN_KEY_PWA_17315)
        } else if (order) {
          this.content.tips.list[0] = template(`<em>${Math.min(+order, +member)}</em>`, this.language.SHEIN_KEY_PWA_17311)
        } else {
          this.content.tips.list[0] = template(`<em>${member}</em>`, this.language.SHEIN_KEY_PWA_17311)
        }
      } else {
        if (single && order) {
          this.content.tips.list[0] = template(`<em>${single}</em>`, `<em>${order}</em>`, this.language.SHEIN_KEY_PWA_17315)
        } else if (single) {
          this.content.tips.list[0] = template(`<em>${single}</em>`, this.language.SHEIN_KEY_PWA_17309)
        } else if (order) {
          this.content.tips.list[0] = template(`<em>${order}</em>`, this.language.SHEIN_KEY_PWA_17311)
        }
      }
       */
      this.content.tips.text = this.content.tips.list[0] || ''
      this.content.tip = this.content.tips.text
      this.content.tipsIndex = 0
    }
    this.content.lable = this.scene === 'detail' ? this.language.SHEIN_KEY_PWA_15055 : ''
  }

  /**
   * 13.加价购
   * 差额提示: -
   * 多档次: 是
   * @ruleType quantity: 满件加价购; price: 满元加价购(默认)
   * @ruleDimension price: 以固定金额加购; discount: 以折扣加购
   */
  handle13(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      rules.forEach((r, i) => {
        const buyLimitAmountWithSymbol = r.value_amount?.amountWithSymbol
        const buyLimitAmount = r.value
        const redemptionPriceAmountWithSymbol = r.discount?.value_amount?.amountWithSymbol
        const redemptionPriceAmount = r.discount?.value

        if (r.type == 1) {
          // 满件type:1
          if (r.discount?.type == 9) { // discount.type=9按固定金额换购时
            this.content.tips.list[i] = template(buyLimitAmount, redemptionPriceAmountWithSymbol, htmlDecode(this.language.SHEIN_KEY_PWA_17004)) // 按元换购
          } else if (r.discount?.type == 10) { // discount.type=10按特价打折换购时
            this.content.tips.list[i] = template(buyLimitAmount, percentNumWithoutSign(redemptionPriceAmount), htmlDecode(this.language.SHEIN_KEY_PWA_17002)) // 按折扣换购
          }
        } else {
          // 满元type:3
          if (r.discount?.type == 9) { // discount.type=9按固定金额换购时
            this.content.tips.list[i] = template(buyLimitAmountWithSymbol, redemptionPriceAmountWithSymbol, htmlDecode(this.language.SHEIN_KEY_PWA_17005)) // 按元换购
          } else if (r.discount?.type == 10) { // discount.type=10按特价打折换购时
            this.content.tips.list[i] = template(buyLimitAmountWithSymbol, percentNumWithoutSign(redemptionPriceAmount), htmlDecode(this.language.SHEIN_KEY_PWA_17003)) // 按折扣换购
          }
        }

      })

      // 购物车场景
      if (this.scene == 'cart') {
        if (promotion_range_info) {
          const { range, next, diff }  = promotion_range_info
          const rule = rules[0]
          const diffValue = rule.type == 1 ? diff.value : diff?.value_amount?.amountWithSymbol

          if (range <= 0) { // 不满足最低档
            this.content.tipsIndex = 0
            this.content.action = 1
            // 加价购特殊的差额文案，仅为满足最低档时存在
            this.content.tip = template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27768)

          } else if (next != '0') { // 满足最低档，但不满足最高档
            this.content.tip = {
              '0': template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27769), // 未勾选附属品
              '1': template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27772), // 勾选附属品
            }
            this.content.action = 2
            this.content.tipsIndex = range - 1
          } else if (next == '0') { // 满足最高档
            this.content.tip = {
              '0': rules.length == 1 ? this.language.SHEIN_KEY_PWA_27770 : this.language.SHEIN_KEY_PWA_27771, // 未勾选附属品
              '1': this.language.SHEIN_KEY_PWA_27773, // 勾选附属品
            }
            this.content.action = 2
            this.content.tipsIndex = range - 1
          }
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15828
  }

  /**
   * 14.满减
   * 差额提示: 是
   * 多档次: 是
   * @goods?.type 1、选品链接ID 2、指定商品SKC 3、全场商品
   * @rule.type 1: 满X件; 2: 每满X件; 3: 满X元;  4: 每满X元
   * @rule.discount?.type 6:减元
   * @rule?.maxdiscount 有值并且>0时，表示活动配置了最大优惠限制
   */
  handle14(){
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
    let { rules = [], goods, promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      rules.forEach((r, i) => {
        const discountValueAmountWithSymbol = r.discount?.value_amount?.amountWithSymbol
        const buyLimitAmountWithSymbol = r.value_amount?.amountWithSymbol
        const buyLimitAmount = r.value
        if ((r.type == 3 || r.type == 4) && r.discount?.type == 6) {
          this.content.tips.list[i] = template(`<em>${discountValueAmountWithSymbol}</em>`, `<em>${buyLimitAmountWithSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_28908))
        } else if((r.type == 1 || r.type == 2) && r.discount?.type == 6){
          this.content.tips.list[i] = template(`<em>${discountValueAmountWithSymbol}</em>`, `<em>${buyLimitAmount}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_28909))
        }
      })
      // 购物车场景
      if (this.scene == 'cart') {
        // 是否每满活动
        const isEveryFull = rules[0].type == 2 || rules[0].type == 4
        if ( promotion_range_info ) { //goods?.type != 3 非全场 goods?.type == 3 全场
          const { range, next, diff = {}, hit_range = {} } = promotion_range_info
          if(goods?.type != 3) { // 非全场
            if (range <= 0) { // 不满足最低档
              let rule = rules[0]
              if(rule.type == 3 && rule.discount?.type == 6) { // 满元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27750)
                )
              } else if(rule.type == 1 && rule.discount?.type == 6) { // 满件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value }</em>`,
                  `<em>${ diff.value }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27753)
                )
              } else if (rule.type == 2 && rule.discount?.type == 6) { // 每满X件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value }</em>`,
                  `<em>${ diff.value }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_29552)
                )
              } else if(rule.type == 4 && rule.discount?.type == 6) { // 每满X元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_29556)
                )
              }
              this.content.tipsIndex = 0
              this.content.action = 1
              this.calcDifferenceTip(diff)
            } else if (next != '0') { // 满足最低档，但不满足最高档（每满活动可以无限触发）
              let rule = isEveryFull ? rules[0] : rules[range - 1]
              let nextRule = rules[range] || {}
              // 是否有最大优惠限制
              const hasMaxDiscount = rule?.max_discount > 0
              // 是否已满足最大优惠限制
              const isFullMaxDiscount = hasMaxDiscount && hit_range.total_discount_amount?.amount >= rule?.max_discount
              if(rule && rule.type == 3 && rule.discount?.type == 6) { // 满元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ nextRule.discount?.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27752)
                )
              } else if(rule && rule.type == 1 && rule.discount?.type == 6) { // 满件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value }</em>`,
                  `<em>${ nextRule.discount?.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27752)
                )
              } else if (rule.type == 2 && rule.discount?.type == 6) { // 每满X件
                if (isFullMaxDiscount) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29555) // 'Eligible to enjoy {0} OFF! Max Savings Reached!'
                  )
                  this.content.discountListSubTip = this.language.SHEIN_KEY_PWA_29561
                } else if (diff.value == rule.value) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29553) // 'Eligible to enjoy {0} OFF!! Add more save more!'
                  )
                } else if (diff.value < rule.value) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    `<em>${ diff.value }</em>`,
                    `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29554) // 'Already saved {0}! Add {1} more for next {2} OFF!'
                  )
                }
              } else if(rule.type == 4 && rule.discount?.type == 6) { // 每满X元
                if (isFullMaxDiscount) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29555) // 'Eligible to enjoy {0} OFF! Max Savings Reached!'
                  )
                  this.content.discountListSubTip = this.language.SHEIN_KEY_PWA_29561
                } else {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                    `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29554) // 'Already saved {0}! Add {1} more for next {2} OFF!'
                  )
                }
              }
              this.content.action = isFullMaxDiscount ? 0 : 1
              this.calcDifferenceTip(diff)
              this.content.tipsIndex = isEveryFull ? 0 : range - 1
            } else if (next == '0') { // 满足最高档
              const rangeListTop = rules[range - 1] || {}
              const discountValueAmountWithSymbol = rangeListTop.discount?.value_amount?.amountWithSymbol
              if (rangeListTop.type == 3 && rangeListTop.discount?.type == 6) {
                this.content.tips.text = template(
                  `<em>${discountValueAmountWithSymbol}</em>`,
                  this.language.SHEIN_KEY_PWA_27751
                ) || ''
              } else if(rangeListTop.type == 1 && rangeListTop.discount?.type == 6){
                this.content.tips.text = template(
                  `<em>${discountValueAmountWithSymbol}</em>`, 
                  this.language.SHEIN_KEY_PWA_27751
                ) || ''
              }
              this.content.action = 0
              this.content.tipsIndex = range - 1
            }
          } else { // 全场
            /**
            if (range == 0) {
              this.content.tips.text = template(`<em>${rules[0].discount?.value_amount?.amountWithSymbol}</em>`, `<em>${rules[0].value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_15833)
            } else if (range > 0) {
              this.content.tips.text = template(`<em>${rules[range - 1].value_amount?.amountWithSymbol}</em>`, `<em>${rules[range - 1].discount?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_15835)
            }
             */
            if (range <= 0) {
              // 不满足最低档
              let rule = rules[0]
              if(rule.type == 3 && rule.discount?.type == 6) { // 满元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27750)
                )
              } else if(rule.type == 1 && rule.discount?.type == 6) { // 满件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value }</em>`,
                  `<em>${ diff.value }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27753)
                )
              } else if (rule.type == 2 && rule.discount?.type == 6) { // 每满X件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value }</em>`,
                  `<em>${ diff.value }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_29552) // '{0} OFF per {1} items. Add {2} more for the deal!'
                )
              } else if(rule.type == 4 && rule.discount?.type == 6) { // 每满X元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ rule.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_29556) // '{0} OFF per {1}. Add {2} more for the deal!'
                )
              }
              this.content.tipsIndex = 0
              this.content.action = 1
              this.content.diffAmount = rules[0].value_amount || null
            } else if (next != '0') {
              // 满足最低档，但不满足最高档（每满活动可以无限触发）
              let rule = isEveryFull ? rules[0] : rules[range - 1] || {}
              let nextRule = rules[range] || {}
              // 是否有最大优惠限制
              const hasMaxDiscount = rule?.max_discount > 0
              // 是否已满足最大优惠限制
              const isFullMaxDiscount = hasMaxDiscount && hit_range.total_discount_amount?.amount >= rule?.max_discount
              if(rule.type == 3 && rule.discount?.type == 6) { // 满元
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ nextRule.discount?.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27752)
                )
              } else if(rule.type == 1 && rule.discount?.type == 6) { // 满件
                this.content.tips.text = template(
                  `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                  `<em>${ diff.value }</em>`,
                  `<em>${ nextRule.discount?.value_amount?.amountWithSymbol }</em>`,
                  htmlDecode(this.language.SHEIN_KEY_PWA_27752)
                )
              } else if (rule.type == 2 && rule.discount?.type == 6) { // 每满X件
                if (isFullMaxDiscount) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29555) // 'Eligible to enjoy {0} OFF! Max Savings Reached!'
                  )
                  this.content.discountListSubTip = this.language.SHEIN_KEY_PWA_29561
                } else if (diff.value == rule.value) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29558) // 'Eligible to enjoy {0} OFF!! Add more save more!'
                  )
                } else if (diff.value < rule.value) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    `<em>${ diff.value }</em>`,
                    `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29554) // 'Already saved {0}! Add {1} more for next {2} OFF!'
                  )
                }
              } else if(rule.type == 4 && rule.discount?.type == 6) { // 每满X元
                if (isFullMaxDiscount) {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29555) // 'Eligible to enjoy {0} OFF! Max Savings Reached!'
                  )
                  this.content.discountListSubTip = this.language.SHEIN_KEY_PWA_29561
                } else {
                  this.content.tips.text = template(
                    `<em>${ hit_range.total_discount_amount?.amountWithSymbol }</em>`,
                    `<em>${ diff.value_amount?.amountWithSymbol }</em>`,
                    `<em>${ rule.discount?.value_amount?.amountWithSymbol }</em>`,
                    htmlDecode(this.language.SHEIN_KEY_PWA_29554) // 'Already saved {0}! Add {1} more for next {2} OFF!'
                  )
                }
              }
              this.content.tipsIndex = isEveryFull ? 0 : range - 1
              this.content.action = isFullMaxDiscount ? 0 : 1
              this.content.diffAmount = diff?.type == 2 ? diff.value_amount || null : null
            } else if (next == '0') {
              // 满足最高档
              const rangeListTop = rules[range - 1] || {}
              const discountValueAmountWithSymbol = rangeListTop.discount?.value_amount?.amountWithSymbol
              if (rangeListTop.type == 3 && rangeListTop.discount?.type == 6) {
                this.content.tips.text = template(
                  `<em>${discountValueAmountWithSymbol}</em>`,
                  this.language.SHEIN_KEY_PWA_27751
                ) || ''
              } else if(rangeListTop.type == 1 && rangeListTop.discount?.type == 6){
                this.content.tips.text = template(
                  `<em>${discountValueAmountWithSymbol}</em>`, 
                  this.language.SHEIN_KEY_PWA_27751
                ) || ''
              }
              this.content.action = 0
              this.content.tipsIndex = range - 1
            }
          }
          this.content.tip = this.content.tips.text //  + this.content.difference.text
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
      let rule = rules[0] || {}
      this.content.lable = rule.type == 3 && rule.discount?.type == 6 ? template(rule.discount?.value_amount?.amountWithSymbol, rule.value_amount?.amountWithSymbol, htmlDecode(this.language.SHEIN_KEY_PWA_15833)) : template(rule.discount?.value_amount?.amountWithSymbol, rule.value, htmlDecode(this.language.SHEIN_KEY_PWA_15834))
    }
  }

  /**
   * 15.x元n件
   * 差额提示: 是
   * 多档次: 是
   * @rule.type 1: 满x件; 2: 每满X件
   * @rule.discount?.type 7: M元任选X件
   */
  handle15(){
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    const type = rules[0]?.type
    const discountType = rules[0]?.discount?.type
    const length = rules.length
    if (length) {
      rules.forEach((r, i) => {
        if (r.type == 1 && r.discount?.type == 7) {
          this.content.tips.list[i] = template(`<em>${r.discount?.value_amount?.amountWithSymbol}</em>`, `<em>${r.value}</em>`, this.language.SHEIN_KEY_PWA_28910)
        } else if (r.type == 2 && r.discount?.type == 7) {
          this.content.tips.list[i] = template(`<em>${r.discount?.value_amount?.amountWithSymbol}</em>`, `<em>${r.value}</em>`, this.language.SHEIN_KEY_PWA_28911)
        }
      })
      // 购物车场景
      if (this.scene == 'cart') {
        if (promotion_range_info) {
          const { range, next, diff = {}, hit_range = {} } = promotion_range_info
          if (range <= 0) {
            // 不满足最低档
            const { value, discount } = rules[range] || {}
            if (type == 2) {
              this.content.tips.text = template(
                `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
                `<em>${value}</em>`,
                `<em>${diff?.value}</em>`,
                this.language.SHEIN_KEY_PWA_28584
              )
            } else {
              this.content.tips.text = template(
                `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
                `<em>${value}</em>`,
                `<em>${diff?.value}</em>`,
                this.language.SHEIN_KEY_PWA_28593
              )  
            } 
            this.calcDifferenceTip(diff)
            this.content.action = 1
            this.content.tipsIndex = 0
          } else if (next != '0') {
            // 满足最低档，但不满足最高档（每满x件可以无限触发）
            if (type == 2) {
              const { value } = rules[0] || {}
              if (diff?.value == value) {
                this.content.tips.text = template(
                  `<em>${hit_range?.hit_range_num}</em>`,
                  `<em>${hit_range?.total_discount_amount?.amountWithSymbol}</em>`,
                  this.language.SHEIN_KEY_PWA_28596
                )
              } else if (diff?.value < value) {
                this.content.tips.text = template(
                  `<em>${hit_range?.hit_range_num}</em>`,
                  `<em>${hit_range?.total_discount_amount?.amountWithSymbol}</em>`,
                  `<em>${diff?.value}</em>`,
                  this.language.SHEIN_KEY_PWA_28597
                )
              }
              this.content.tipsIndex = 0
            } else {
              const { value, discount } = rules[range] || {}

              // 已满足的档次
              const { value: beforeValue, discount: beforeDiscount } = rules[range - 1] || {}
              if (length > 1) {
                // 多档
                this.content.tips.text = template(
                  `<em>${beforeValue}</em>`,
                  `<em>${beforeDiscount?.value_amount?.amountWithSymbol}</em>`,
                  `<em>${diff?.value}</em>`,
                  `<em>${value}</em>`,
                  `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
                  this.language.SHEIN_KEY_PWA_28595
                )  
              }
              this.content.tipsIndex = range - 1
            }
            this.calcDifferenceTip(diff)
            this.content.action = 1
          } else if (next == '0') {
            // 满足最高档
            const { value, discount } = rules[range - 1] || {}
            this.content.tips.text = template(
              `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
              `<em>${value}</em>`,
              this.language.SHEIN_KEY_PWA_28594
            )  
            if (type == 2 && discountType == 7) {
              this.content.action = 1
              this.content.tipsIndex = 0
            } else {
              this.content.tipsIndex = range - 1
            }
          }
          this.content.tip = this.content.tips.text
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
      this.content.lable = template(rules[0].discount?.value_amount?.amountWithSymbol, rules[0].value, this.language.SHEIN_KEY_PWA_17369)
    }
  }

  /**
   * 非购物车活动
   * 16.每买N减N (减元)
   * 差额提示: -
   * 多档次: -
   */
  handle16(){
    let { rules = [] }  = this.promotionInfo
    if (rules.length) {
      if (rules.length == 2) {
        this.content.tips.list[0] = template(`<em>${rules[0].discount?.value_amount?.amountWithSymbol}</em>`, `<em>${rules[1].discount?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_16000)
      } else if (rules.length == 1) {
        this.content.tips.list[0] = template(`<em>${rules[0].discount?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_15998)
      }

      if (this.scene !== 'cart') {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 非购物车活动
   * 17.每买N减N (折扣)
   * 差额提示: -
   * 多档次: -
   */
  handle17(){
    let { rules = [] }  = this.promotionInfo
    if (rules.length) {
      if (rules.length == 2) {
        this.content.tips.list[0] = template(`<em>${decimalToPercent((100 - rules[0].discount?.value * 100).toFixed(0))}</em>`, `<em>${decimalToPercent((100 - rules[1].discount?.value * 100).toFixed(0))}</em>`, this.language.SHEIN_KEY_PWA_15999)
      } else if (rules.length == 1) {
        this.content.tips.list[0] = template(`<em>${decimalToPercent((100 - rules[0].discount?.value * 100).toFixed(0))}</em>`, this.language.SHEIN_KEY_PWA_15997)
      }
      if (this.scene !== 'cart') {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 18.买n减n（减元）
   * 差额提示: -
   * 多档次: -
   */
  handle18(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      if (rules.length == 2) {
        this.content.tips.list[0] = template(`<em>${rules[0].discount?.value_amount?.amountWithSymbol}</em>`, `<em>${rules[1].discount?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_16000)
      } else if (rules.length == 1) {
        this.content.tips.list[0] = template(`<em>${rules[0].discount?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_15998)
      }

      // 购物车场景
      if (this.scene === 'cart') {
        if(promotion_range_info){
          if (promotion_range_info.range === 0) { // 未满足最高梯度
            this.content.action = 1
          } else if (promotion_range_info.range > 0) { // 满足最高梯度
            this.content.action = 0
          }

          this.content.tips.text = this.content.tips.list[0] || ''
          this.content.tip = this.content.tips.text + this.content.difference.text
          this.content.tipsIndex = 0
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 19.买n减n（折扣）
   * 差额提示: -
   * 多档次: -
   * @discount?.type 3: 按特价折扣; 1: 按原价折(默认)
   */
  handle19(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      if (rules[0].discount?.type == '3') {
        if (rules.length == 2) {
          this.content.tips.list[0] = template(`<em>${percentNumWithoutSign(rules[0].discount.amount)}</em>`, `<em>${percentNumWithoutSign(rules[1].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_16888)
        } else if (rules.length == 1) {
          this.content.tips.list[0] = template(`<em>${percentNumWithoutSign(rules[0].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_16887)
        }
      } else {
        if (rules.length == 2) {
          if (['tw', 'hk'].includes(this.lang)) {
            this.content.tips.list[0] = template(`<em>${discountNumCn(rules[0].discount.amount)}</em>`, `<em>${discountNumCn(rules[1].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_15999)
          } else {
            this.content.tips.list[0] = template(`<em>${decimalToPercent(rules[0].discount.amount)}</em>`, `<em>${decimalToPercent(rules[1].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_15999)
          }
        } else if (rules.length == 1) {
          if (['tw', 'hk'].includes(this.lang)) {
            this.content.tips.list[0] = template(`<em>${discountNumCn(rules[0].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_15997)
          } else {
            this.content.tips.list[0] = template(`<em>${decimalToPercent(rules[0].discount.amount)}</em>`, this.language.SHEIN_KEY_PWA_15997)
          }
        }
      }
      // 购物车场景
      if ( this.scene === 'cart' ) {
        if(promotion_range_info){
          if (promotion_range_info.range === 0) { // 未满足最高梯度
            this.content.action = 1
          } else if (promotion_range_info.range > 0) { // 满足最高梯度
            this.content.action = 0
          }

          this.content.tips.text = this.content.tips.list[0] || ''
          this.content.tip = this.content.tips.text + this.content.difference.text
          this.content.tipsIndex = 0
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 20.订单返券
   * 差额提示: -
   * 多档次: 是
   */
  handle20(){
    let { rules = [], promotion_range_info = null, coupons }  = this.promotionInfo
    if (rules.length) {
      rules.forEach((r, i) => {
        let coupon = {}
        if(r.discount?.gift_coupons && coupons) {
          let gift_coupons = r.discount.gift_coupons?.map(couponItem => couponItem.toLowerCase()) || []
          coupon = coupons.find(item => gift_coupons.includes(item.coupon_code.toLowerCase()))
        }
        if(!coupon) {
          this.content.tips.list[i] = ''
          return
        }
        const buyLimitAmountWithSymbol = r.value_amount?.amountWithSymbol
        const couponPriceSymbol = coupon.rule?.[0]?.value?.amountWithSymbol

        if (coupon.apply_for == '5' || coupon.apply_for == '6') {
          this.content.tips.list[i] = template(buyLimitAmountWithSymbol, htmlDecode(this.language.SHEIN_KEY_PWA_15996))
        } else {
          this.content.tips.list[i] = template(buyLimitAmountWithSymbol, couponPriceSymbol, htmlDecode(this.language.SHEIN_KEY_PWA_15994))
        }
      })

      // 购物车场景
      if (this.scene === 'cart') {
        // 获取特殊的购物车文案
        const getCurrentTip = ({ currentRangeList, diffAmount, isReached } = {}) => {
          let coupon = {}
          if(currentRangeList.discount?.gift_coupons && coupons) {
            let gift_coupons = currentRangeList.discount.gift_coupons?.map(couponItem => couponItem.toLowerCase()) || []
            coupon = coupons.find(item => gift_coupons.includes(item.coupon_code.toLowerCase()))
          }
          if(!coupon) return  ''
          const couponPriceSymbol = coupon.rule_dimension == 2 || coupon.rule_dimension == 5 
            ? numberToPercentage(coupon.rule?.[coupon.rule?.length - 1]?.value?.amount) : 
            coupon.rule?.[coupon.rule?.length - 1]?.value?.amountWithSymbol
          const diffAmountWithSymbol = diffAmount?.amountWithSymbol
          const buyLimitAmountWithSymbol = currentRangeList.value_amount?.amountWithSymbol

          if (coupon.apply_for == '5' || coupon.apply_for == '6') { // 免邮券
            return template(`<em>${buyLimitAmountWithSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_15996))
          } else if (coupon.rule_dimension == 1) {  // 金额券
            return template(`<em>${buyLimitAmountWithSymbol}</em>`, `<em>${couponPriceSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_15994))
          } else if (coupon.rule_dimension == 2) {  // 原价折
            return isReached ? template(`<em>${buyLimitAmountWithSymbol}</em>`, `<em>${couponPriceSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_18122)) :
              template(`<em>${diffAmountWithSymbol}</em>`, `<em>${couponPriceSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_18124))
          } else if (coupon.rule_dimension == 5) {  // 特价折
            return isReached ? template(`<em>${buyLimitAmountWithSymbol}</em>`, `<em>${couponPriceSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_18123)) :
              template(`<em>${diffAmountWithSymbol}</em>`, `<em>${couponPriceSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_18125))
          }
        }

        if (promotion_range_info) {
          const { range, next, diff } = promotion_range_info
          if (range <= 0) {
            // 不满足最低档
            this.content.tips.text = this.content.tips.list[0] || ''
            this.content.action = 1
            this.content.tipsIndex = 0
            // 购物车的提示
            const currentRangeList = rules[0]
            this.content.tip = getCurrentTip({ currentRangeList, diffAmount: diff?.value_amount, isReached: false })
          } else if (next != '0') {
            // 满足最低档，但不满足最高档
            this.content.tips.text = this.content.tips.list[range] || ''
            this.content.action = 1
            this.content.tipsIndex = range - 1
            // 购物车的提示
            const currentRangeList = rules[range]
            this.content.tip = getCurrentTip({ currentRangeList, diffAmount: diff?.value_amount, isReached: false })
          } else if (next == '0') {
            // 满足最高档
            this.content.tips.text = this.content.tips.list[range - 1] || ''
            this.content.action = 0
            this.content.tipsIndex = range - 1
            // 购物车的提示
            const currentRangeList = rules[range - 1]
            this.content.tip = getCurrentTip({ currentRangeList, diffAmount: diff?.value_amount, isReached: true })
          }
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15882
  }

  /**
   * 21.订单返券(全场)
   * 差额提示: -
   * 多档次: 是
   */
  handle21(){
    let { rules = [], promotion_range_info = null, coupons, coupon_num, total_value, all_full_reduce_flag }  = this.promotionInfo
    if(rules.length){
      if (this.scene === 'cart') {
        if (promotion_range_info) {
          const { range, next, diff = {} } = promotion_range_info
          let index = 0 // 活动 tip 展示
          let isReached = false // 是否满足最高档

          if (range <= 0) {
            // 不满足最低档
            index = 0
            isReached = false
            this.content.action = 1
            this.content.diffAmount = diff?.value_amount || null
          } else if (next != '0') {
            // 满足最低档，但不满足最高档
            index = range
            isReached = false
            this.content.action = 1
            this.content.diffAmount = diff?.value_amount || null
          } else if (next == '0') {
            // 满足最高档
            index = range - 1
            isReached = true
            this.content.action = 0
          }

          const rangeListItem = rules[index]
          if (!coupon_num || coupon_num == 1) { // 返券
            let coupon = null
            if(rangeListItem.discount?.gift_coupons && coupons) {
              let gift_coupons = rangeListItem.discount.gift_coupons?.map(couponItem => couponItem.toLowerCase()) || []
              coupon = coupons.find(item => gift_coupons.includes(item.coupon_code.toLowerCase()))
            }
            if (coupon) {
              let couponTips = this.getCouponTips()
              const isFreeShippingCoupon = coupon.apply_for == '5' || coupon.apply_for == '6'
              const couponPriceSymbol = coupon.rule_dimension == 2 || coupon.rule_dimension == 5 ?
                numberToPercentage(coupon.rule?.[coupon.rule?.length - 1]?.value?.amount) : 
                coupon.rule?.[coupon.rule?.length - 1]?.value?.amountWithSymbol
              const buyLimitAmountWithSymbol = rangeListItem.value_amount?.amountWithSymbol
              const diffAmountWithSymbol = diff.value_amount?.amountWithSymbol
              const delayDays = +coupon.coupon_after_day
              const tipIndex = parseInt(`${+isReached}${+Boolean(delayDays)}`, 2)
              const tipPriceWithSymbol = isReached ? buyLimitAmountWithSymbol : diffAmountWithSymbol
              if (isFreeShippingCoupon) { // 减最低运费券
                this.content.tip = template(
                  `<em>${tipPriceWithSymbol}</em>`,
                  `<em>${delayDays}</em>`,
                  htmlDecode(couponTips.freeShipTips[tipIndex])
                )
              } else if (coupon.rule_dimension == 2) {  // 原价折
                this.content.tip = template(
                  `<em>${tipPriceWithSymbol}</em>`,
                  `<em>${couponPriceSymbol}</em>`,
                  `<em>${delayDays}</em>`,
                  htmlDecode(couponTips.couponTypeId2[tipIndex])
                )
              } else if (coupon.rule_dimension == 5) {  // 特价折
                this.content.tip = template(
                  `<em>${tipPriceWithSymbol}</em>`,
                  `<em>${couponPriceSymbol}</em>`,
                  `<em>${delayDays}</em>`,
                  htmlDecode(couponTips.couponTypeId5[tipIndex])
                )
              } else {
                this.content.tip = template(
                  `<em>${tipPriceWithSymbol}</em>`,
                  `<em>${couponPriceSymbol}</em>`,
                  `<em>${delayDays}</em>`,
                  htmlDecode(couponTips.couponTypeIdOther[tipIndex])
                )
              }
            }
          } else if (coupon_num > 1) { // 返券包
            const tipPriceWithSymbol = isReached ? rangeListItem.value_amount?.amountWithSymbol : diff.value_amount?.amountWithSymbol
            if (all_full_reduce_flag == 1) {  // 全金额券
              this.content.tip = this.content.action == 0
                ? template(`<em>${total_value?.amountWithSymbol}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_26768)) 
                : template(
                  `<em>${tipPriceWithSymbol}</em>`, 
                  `<em>${total_value?.amountWithSymbol}</em>`, 
                  htmlDecode(this.language.SHEIN_KEY_PWA_26605)
                )
            } else {
              this.content.tip = this.content.action == 0
                ? template(`<em>${coupon_num}</em>`, htmlDecode(this.language.SHEIN_KEY_PWA_26769)) 
                : template(
                  `<em>${tipPriceWithSymbol}</em>`, 
                  `<em>${coupon_num}</em>`, 
                  htmlDecode(this.language.SHEIN_KEY_PWA_26606)
                )
            }
          }
        }
      }
    }
  }

  /**
   * 22.全场加价购
   * 差额提示: -
   * 多档次: -
   * @rule.type 1: 满X件; 3: 满X元
   */
  handle22(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      let rule = rules[0]
      if (this.scene === 'cart') {
        if(promotion_range_info){
          const { range, diff, next } = promotion_range_info
          const diffValue = rule.type == 1 ? diff.value : diff?.value_amount?.amountWithSymbol
          if (range <= 0) {
            this.content.action = 1
            this.content.tip = template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27768)

            this.content.difference.diffType = rule.type
            this.content.difference.diff = diff.value
            this.content.difference.diffAmount = diff?.value_amount?.amount
          } else if (next !== 0) {
            this.content.action = 2
            this.content.tip = {
              '0': template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27769), // 未勾选附属品
              '1': template(`<em>${diffValue}</em>`, this.language.SHEIN_KEY_PWA_27772), // 勾选附属品
            }
          } else {
            this.content.action = 2
            this.content.tip = {
              '0': rules.length == 1 ? this.language.SHEIN_KEY_PWA_27770 : this.language.SHEIN_KEY_PWA_27771, // 未勾选附属品
              '1': this.language.SHEIN_KEY_PWA_27773, // 勾选附属品
            }
          }
        }
      }
    }
  }

  /**
   * 24.打折限购
   * 差额提示: -
   * 多档次: -
   * @discount.type 3: 按特价折扣; 1: 按原价折; 15: 非打折
   * @single 单品限购数
   * @order 订单限购数
   */
  handle24(){
    let { rules = [], cart_limit }  = this.promotionInfo
    let { single = 0, order = 0, member = 0 } = cart_limit || {}
    let singleNum = Number(single)
    let orderNum = Math.max(Number(order), Number(member))
    let rule = rules[0] || {}
    if (this.scene == 'cart') {
      if ([1, 3].includes(+rule.discount?.type)) {
        if (singleNum && orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(singleNum)}</em>`, `<em>${Number(orderNum)}</em>`, this.language.SHEIN_KEY_PWA_17315)
        } else if (singleNum && !orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(singleNum)}</em>`, this.language.SHEIN_KEY_PWA_17309)
        } else if (!singleNum && orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(orderNum)}</em>`, this.language.SHEIN_KEY_PWA_17311)
        } else {
          this.content.tips.list[0] = template(decimalToPercent(rule.discount.value), this.language.SHEIN_KEY_PWA_27524)
        }
      } else {
        if (singleNum && orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(singleNum)}</em>`, `<em>${Number(orderNum)}</em>`, this.language.SHEIN_KEY_PWA_17313)
        } else if (singleNum && !orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(singleNum)}</em>`, this.language.SHEIN_KEY_PWA_17308)
        } else if (!singleNum && orderNum) {
          this.content.tips.list[0] = template(`<em>${Number(orderNum)}</em>`, this.language.SHEIN_KEY_PWA_17310)
        } else {
          this.content.tips.list[0] = this.language.SHEIN_KEY_PWA_27525
        }
      }
    } else {
      const discountTip = {
        sale_discount: () => {
          return `${this.language.SHEIN_KEY_PWA_17320}${singleNum ? template(Number(singleNum), this.language.SHEIN_KEY_PWA_17321) : ''}${orderNum ? template(Number(orderNum), this.language.SHEIN_KEY_PWA_17324) : ''}`
        },
        no_discount: () => {
          return `${singleNum && orderNum ? template(Number(singleNum), this.language.SHEIN_KEY_PWA_17321) : singleNum ? template(Number(singleNum), this.language.SHEIN_KEY_PWA_17326) : ''}${orderNum ? template(Number(orderNum), this.language.SHEIN_KEY_PWA_17327) : ''}`
        }
      }
      const setRuleType = rule.discount?.type == 15 ? 'no_discount' : 'sale_discount'
      this.content.tips.list[0] = discountTip[setRuleType]() || ''
      this.content.style = rule.discount?.type == 15 ? {
        color: '#000'
      } : {}
    }

    this.content.tips.text = this.content.tips.list[0] || ''
    this.content.tip = this.content.tips.text + this.content.difference.text
    this.content.tipsIndex = 0
    this.content.lable = rule.discount?.type == 15 ? '' : this.language.SHEIN_KEY_PWA_17317
  }

  /**
   * 25.满折
   * 差额提示: 非常规
   * 多档次: 是
   * @discount.type 3: 按特价折扣; 1: 按原价折;
   */
  handle25(){
    let { rules = [], promotion_range_info = null }  = this.promotionInfo
    if (rules.length) {
      rules.forEach((r, i) => {
        if (r.discount.type == 3) {
          this.content.tips.list[i] = template(`<em>${decimalToPercent(r.discount?.value)}</em>`, `<em>${r.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_28912)
        } else {
          this.content.tips.list[i] = template(`<em>${decimalToPercent(r.discount?.value)}</em>`, `<em>${r.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_28912)
        }
      })

      // 购物车场景
      if (this.scene === 'cart') {
        if (promotion_range_info) {
          const { range, next, diff } = promotion_range_info
          if (range <= 0) {
            // 不满足最低档
            const currentRangeList = rules[0]
            if (currentRangeList.discount?.type == 3) {
              this.content.tips.text = template(
                `<em>${diff?.value_amount?.amountWithSymbol}</em>`,
                `<em>${decimalToPercent(currentRangeList.discount?.value)}</em>`,
                this.language.SHEIN_KEY_PWA_27747.replace('%', '')
              )
            } else {
              this.content.tips.text = template(
                `<em>${diff?.value_amount?.amountWithSymbol}</em>`, 
                `<em>${decimalToPercent(currentRangeList.discount?.value)}</em>`, 
                this.language.SHEIN_KEY_PWA_27746.replace('%', '')
              )
            }
            this.content.action = 1
            this.content.tipsIndex = 0
            this.content.diffAmount = diff?.value_amount || null
          } else if (next != '0') {
            // 满足最低档，但不满足最高档
            const currentRangeList = rules[range - 1]
            const nextRule = rules[range]
            if (currentRangeList.discount?.type == 3) {
              this.content.tips.text = template(
                `<em>${ decimalToPercent(currentRangeList.discount?.value) }</em>`,
                `<em>${ diff?.value_amount?.amountWithSymbol }</em>`, 
                `<em>${ decimalToPercent(nextRule.discount?.value) }</em>`, 
                this.language.SHEIN_KEY_PWA_27749.replace('%', '')
              )
            } else {
              this.content.tips.text = template(
                `<em>${ decimalToPercent(currentRangeList.discount?.value) }</em>`,
                `<em>${ diff?.value_amount?.amountWithSymbol }</em>`, 
                `<em>${ decimalToPercent(nextRule.discount?.value) }</em>`, 
                this.language.SHEIN_KEY_PWA_27748.replace('%', '')
              )
            }
            this.content.action = 1
            this.content.tipsIndex = range - 1
            this.content.diffAmount = diff?.value_amount || null
          } else if (next == '0') {
            // 满足最高档
            const currentRangeList = rules[range - 1]
            if (currentRangeList.discount?.type == 3) {
              this.content.tips.text = template(
                `<em>${ decimalToPercent(currentRangeList.discount?.value) }</em>`, 
                this.language.SHEIN_KEY_PWA_27743.replace('%', '')
              )
            } else {
              this.content.tips.text = template(
                `<em>${ decimalToPercent(currentRangeList.discount?.value) }</em>`, 
                this.language.SHEIN_KEY_PWA_27741.replace('%', '')
              )
            }
            this.content.action = 0
            this.content.tipsIndex = range - 1
          }

          this.content.tip = this.content.tips.text + this.content.difference.text
        }
      } else {
        this.content.tips.text = this.content.tips.list[0] || ''
      }
    }
    this.content.lable = this.language.SHEIN_KEY_PWA_15880
  }

  /**
   * 26.满折（全场）
   * 差额提示: 非常规
   * 多档次: 是
   * @discount.type 3: 按特价折扣; 1: 按原价折;
   */
  handle26(){
    this.handle25()
  }

  /**
   * 28. 满赠（非全场 有品牌）
   * 差额提示: Spend {0} more on {1} items to earn a FREE GIFT 
   * 0 差额 1 品牌
   * type：1、选品链接ID 2、指定商品SKC 3、全场商品 4、品牌
   * 多档次: 是
   */
  handle28(){
    let { promotion_range_info, goods, rules }  = this.promotionInfo
    // 购物车场景
    if (this.scene == 'cart') {
      // let lowerProductRange = -1
      // rules.forEach((rule, index)=>{
      //   if (rule.list?.length && lowerProductRange == -1) {
      //     lowerProductRange = index + 1
      //   }
      // })
      if (promotion_range_info) {
        const { range, all_diff, next } = promotion_range_info || {}
        const { type, brand_name } = goods || {}
        let diff = all_diff && all_diff[range] || {}
        if (range <= 0) {
          // 未满足
          this.content.action = 1
          if (type == 4) {
            this.content.tip = template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, brand_name, this.language.SHEIN_KEY_PWA_27780)
          } else {
            this.content.tip = template(`<em>${diff?.value_amount?.amountWithSymbol} </em>`, this.language.SHEIN_KEY_PWA_27774)
          }
        } else if(next !== 0) {
          // next 下一档位，为0时表示当前为最高档
          this.content.action = 2
          this.content.tip = {
            '0': template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_27775), // 未勾选附属品
            '1': template(`<em>${diff?.value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_27778), // 勾选附属品
          }
        } else {
          this.content.tip = {
            '0': rules.length == 1 ? this.language.SHEIN_KEY_PWA_27776 : this.language.SHEIN_KEY_PWA_27777, // 未勾选附属品
            '1': this.language.SHEIN_KEY_PWA_27779, // 勾选附属品
          }
          this.content.action = 2
        }

        let index = Math.min(range - 1, rules.length - 1)
        // 外漏的赠品列表,取最高档次的有可售赠品列表
        let showFullGiftList = []
        while (index >= 0) {
          if(rules[index].list?.length){
            showFullGiftList = rules[index].list?.filter(goods => goods.on_sale_status == '1' && goods.stock_status == '1') || []
          }
          if(showFullGiftList.length){
            break
          }
          index--
        }
        this.content.showFullGiftList = showFullGiftList
      }
    }
  }


  getContent(){
    return this.content
  }

  /**
   *
   * @param value
   * @param type 1:件 2：金额
   * @param value_amount
   */
  calcDifferenceTip ({ value = '', type = 1, value_amount = {} }) {
    if (type == 2) {
      this.content.difference.text = template(`<em>${value_amount?.amountWithSymbol}</em>`, this.language.SHEIN_KEY_PWA_15979)
    } else if (type == 1) {
      this.content.difference.text = template(`<em>${value}</em>`, this.language.SHEIN_KEY_PWA_15980)
    }
    this.content.difference.diff = value
    this.content.difference.diffType = type
    this.content.difference.diffAmount = value_amount
  }

  getCouponTips () {
    let language = this.language
    return {
      freeShipTips: [language.SHEIN_KEY_PWA_18248, language.SHEIN_KEY_PWA_18249, language.SHEIN_KEY_PWA_18246, language.SHEIN_KEY_PWA_18247],
      couponTypeId2: [language.SHEIN_KEY_PWA_18124, language.SHEIN_KEY_PWA_18244, language.SHEIN_KEY_PWA_18122, language.SHEIN_KEY_PWA_18242],
      couponTypeId5: [language.SHEIN_KEY_PWA_18125, language.SHEIN_KEY_PWA_18245, language.SHEIN_KEY_PWA_18123, language.SHEIN_KEY_PWA_18243],
      couponTypeIdOther: [language.SHEIN_KEY_PWA_18251, language.SHEIN_KEY_PWA_18253, language.SHEIN_KEY_PWA_18250, language.SHEIN_KEY_PWA_18252]
    }
  }
}



/**
 * 添加处理促销提示语
 * @param {Object} promotionInfo [异步获取的活动信息]
 * @param {Object} language [多语言]
 * @param {String} scene [场景参数，区分购物车和列表详情页]
 * @return {Object} content [促销活动整合内容]
 */
function handleTransformPromotionTip ({ language = {}, promotionInfo = {}, scene = 'cart', lang = '' }) {
  let transformPromotionTip = new TransformPromotionTip({ language, promotionInfo, scene, lang })
  let type_id = promotionInfo.type_id
  if(typeof transformPromotionTip[`handle${type_id}`] == 'function') {
    transformPromotionTip[`handle${type_id}`]()
    return transformPromotionTip.getContent()
  }
  return {}
}

export { handleTransformPromotionTip }
